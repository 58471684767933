import React, { useState, useEffect, Fragment } from "react";
import { Modal} from "antd";
import './CategoryGateModal.scss';
import CategoryModalItem from './CategoryModalItem';
import  useHttp from '../../hooks/use-http';
import bgImage from '../../assets/images/backgrounds/noise.png';

const { PUBLIC_URL } = process.env;

const CategoryGateModal = React.memo((props) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);
  
  const {loading, error, sendRequest } = useHttp();

  const callback = (data) => {

    setData(data.results);
  };

  useEffect(() => {
    sendRequest({
      url: `${PUBLIC_URL}/api/v3/packagings`,
      method: "POST",
    }, callback);
  }, []);

  // Remove this here
  const bodyStyle = {
    background: `#ccc url('${bgImage}') repeat`,
  }
  
  return (
    <Fragment>
      <Modal visible={props.isVisible} footer={null} destroyOnClose={true} onCancel={props.onCancel} maskClosable={false} keyboard={false} closable={true} bodyStyle={bodyStyle}> 
        <div className="category-modal-container">
          <div className="question-box">
            <h2 className="category-gate-question">Cheers! What are you ordering?</h2>
          </div>
          <div className="package-items">
            {data &&
              data.map(product => <CategoryModalItem key={product.id} product={product} onClickHandler={props.onClickHandler}/>)
            }
          </div>  
        </div>  
      </Modal>
    </Fragment>
  );
});

export default CategoryGateModal;
